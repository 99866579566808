import { defineStore, storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import { ConnectionDTO, UpdateConnectionInput } from "@/types";
import axios from "axios";
import { useAppointmentCalendarStore } from "./appointmentCalendar";
import { useUiStore } from "./ui";
import { sendWsMessage } from "@/utils/websocket";
import { AlertActionEnum, AlertItemTypeEnum } from "@/shared/sharedEnums";

export const useConnectionStore = defineStore("connection", () => {
  const connections = ref<Record<string, ConnectionDTO>>({});
  const currentConnectId = ref<string>();
  const currentConnection = computed({
    get: () => {
      if (!currentConnectId.value) return;
      return connections.value[currentConnectId.value];
    },
    set: (value) => {
      if (!value?.connectionId) return;
      connections.value[value.connectionId] = {
        ...value,
        roomIds: new Set(value.roomIds),
      };
    },
  });

  const setCurrentConnectId = (value?: string) => {
    currentConnectId.value = value;
    if (value) {
      const newObj = { ...connections.value };
      newObj[value] = { connectionId: value };
      connections.value = newObj;
    }
  };

  const refreshConnections = async () => {
    connections.value = {};
    const { currentUser } = useUiStore();
    if (!currentUser) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(refreshConnections());
        }, 2000);
      });
    }
    const { data }: { data: ConnectionDTO[] } = await axios.get("connections");
    setConnections(data);
  };

  const setConnections = (items: ConnectionDTO[]) => {
    const newObj: Record<string, ConnectionDTO> = {};
    items.forEach((connection) => {
      newObj[connection.connectionId] = {
        ...connection,
        roomIds: new Set(connection.roomIds),
      };
    });
    connections.value = {
      ...connections.value,
      ...newObj,
    };
  };

  const removeConnections = (connectionIds: string[]) => {
    const newObj: Record<string, ConnectionDTO> = { ...connections.value };
    connectionIds.forEach((id) => {
      delete newObj[id];
    });
    connections.value = newObj;
  };

  const getConnectionsByRoomId = computed(() => {
    return (roomId: string) =>
      Object.values(connections.value).filter((connection) =>
        connection.roomIds?.has(roomId)
      );
  });

  const getConnectionsByRoomIdButExcludeCurrent = computed(() => {
    const email = useUiStore().currentUser?.email;
    return (roomId: string) => {
      return Object.values(connections.value).filter((connection) => {
        if (email && connection.email === email) {
          // Prevent show self's account
          return false;
        }
        return (
          connection.roomIds?.has(roomId) &&
          currentConnectId.value !== connection.connectionId
        );
      });
    };
  });

  const appointmentCalendarStore = useAppointmentCalendarStore();
  const { roomIds } = storeToRefs(appointmentCalendarStore);

  let updateCurrentConnectionTimeoutId: string | undefined;
  watch(
    [roomIds],
    ([newValue]) => {
      // Update current connection
      clearTimeout(updateCurrentConnectionTimeoutId);
      // Prevent update too often
      updateCurrentConnectionTimeoutId = setTimeout(
        () => updateCurrentConnection(newValue),
        200
      ) as unknown as string;
    },
    { immediate: true }
  );

  const updateCurrentConnection = (roomIds: string[]) => {
    const uiStore = useUiStore();
    if (!uiStore.currentUser) {
      // Wait currentUser
      setTimeout(() => {
        updateCurrentConnection(roomIds);
      }, 1000);
      return;
    }
    roomIds.forEach((roomId) => {
      const connections = getConnectionsByRoomIdButExcludeCurrent.value(roomId);
      if (connections.length) {
        useUiStore().setAlertDialog(true);
        useUiStore().setAlertStaffAccount(
          connections.map((c) => c.email).join(", ")
        );
        const [type] = roomId.split("#");
        useUiStore().setAlertItemType(type as AlertItemTypeEnum);
        useUiStore().setAlertAction(AlertActionEnum.VIEW);
      }
    });
    const { displayName, email } = uiStore.currentUser ?? {};
    const data: UpdateConnectionInput = {
      ...useConnectionStore().currentConnection,
      displayName: displayName ?? undefined,
      email: email ?? undefined,
      roomIds,
    };
    sendWsMessage("updateconnection", { data });
  };

  return {
    connections,
    setConnections,
    removeConnections,
    getConnectionsByRoomId,
    getConnectionsByRoomIdButExcludeCurrent,
    setConnectId: setCurrentConnectId,
    currentConnection,
    refreshConnections,
  };
});
