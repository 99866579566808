import {
  CalendarType,
  AlertItemTypeEnum,
  PatientSelectionMode,
  AlertActionEnum,
} from "@/shared/sharedEnums";
import {
  ALaCarteItem,
  AppointmentGroupCategory,
  AppointmentGroupCheckTypesMetadata,
  AppointmentGroupInput,
  AppointmentRequestDTO,
  AppointmentRequestEditInput,
  AppointmentGroupType,
  CheckType,
  ClinicSite,
  PatientInput,
  PriceFactor,
  TimeSlotInput,
  TransactionInput,
  TransactionEditInput,
  AppointmentGroupEditInput,
} from "@/types";
import { defineStore } from "pinia";
import Vue from "vue";
import { useAppointmentStore } from "./appointment";
import { useOverviewStore } from "./overview";
import { usePatientStore } from "./patient";
import { useAppointmentGroupStore } from "./appointmentGroup";
import { useTimeSlotStore } from "./timeSlot";
import router from "@/router";
import { useAppointmentRequestStore } from "./appointmentRequest";
import moment from "moment";
import { useTransactionStore } from "./transaction";
import { useReceiptStore } from "./receipt";
import {
  Appointment,
  AppointmentGroupAppointmentsLoadingStatus,
  MonthlyCalendarViewMode,
} from "@/shared/sharedTypes";
import { useDayRemarkStore } from "./dayRemark";
import { useUiStore } from "./ui";
import { useUrlSearchParams } from "@vueuse/core";
import { getRoomId } from "@/utils/websocket";
import { AppointmentLocationType } from "@/types";
import { useDayQrCodeStore } from "./dayQrCode";

interface State {
  selectedAppointmentGroupType: AppointmentGroupType | undefined;
  selectedALaCarteItems: ALaCarteItem[] | undefined;
  selectedAppointmentGroupId: string | undefined;
  checkTypesMetadataByAppointmentGroupId: Record<
    string,
    AppointmentGroupCheckTypesMetadata
  >;
  appointmentGroupCategory: AppointmentGroupCategory | undefined;
  selectedCheckTypes: CheckType[] | undefined;
  selectedPatientId: string | undefined;
  patientSelectionMode: PatientSelectionMode | undefined;
  priceFactor: PriceFactor | undefined;
  dueDate: string | undefined;
  fetusCount: number | undefined;
  site: ClinicSite | undefined;
  newBornDob: string | undefined;
  isLoadingAppointmentGroups: boolean;
  appointmentGroupAppointmentsLoadingStatus: Record<
    string,
    AppointmentGroupAppointmentsLoadingStatus
  >;
  isLoadingOverviews: boolean;
  calendarType: CalendarType;
  date: string;
  selectedAppointmentId: string | undefined;
  selectedAppointmentRequestId: string | undefined;
  temporaryAppointmentRequest: AppointmentRequestDTO | undefined;
  rescheduleFromAppointmentId: string | undefined;
  backupAppointmentMetadata: AppointmentGroupCheckTypesMetadata | undefined;
  patientDob: string | undefined;
  isCustomWeekMode: boolean;
  monthlyCalendarViewMode: MonthlyCalendarViewMode;
  searchTerm: string;
}

export const useAppointmentCalendarStore = defineStore("appointmentCalendar", {
  state: (): State => ({
    selectedAppointmentGroupType: undefined,
    selectedALaCarteItems: undefined,
    selectedAppointmentGroupId: undefined,
    checkTypesMetadataByAppointmentGroupId: {},
    selectedCheckTypes: undefined,
    selectedPatientId: undefined,
    patientSelectionMode: undefined,
    priceFactor: undefined,
    appointmentGroupCategory: undefined,
    dueDate: undefined,
    fetusCount: undefined,
    site: undefined,
    newBornDob: undefined,
    isLoadingAppointmentGroups: false,
    appointmentGroupAppointmentsLoadingStatus: {},
    isLoadingOverviews: false,
    calendarType: CalendarType.CATEGORY,
    date: new Date().toISOString().split("T")[0],
    selectedAppointmentId: undefined,
    selectedAppointmentRequestId: undefined,
    temporaryAppointmentRequest: undefined,
    rescheduleFromAppointmentId: undefined,
    backupAppointmentMetadata: undefined,
    patientDob: undefined,
    isCustomWeekMode: false,
    monthlyCalendarViewMode: "show_available",
    searchTerm: "",
  }),
  actions: {
    async handlePatientUpdate(input: { id: string; updater: string }) {
      const patientStore = usePatientStore();
      const oldPatient = patientStore.patients[input.id];
      if (!oldPatient) return;
      if (
        this.selectedPatientId === oldPatient._id &&
        useUiStore().currentUser?.email !== input.updater
      ) {
        useUiStore().setAlertDialog(true);
        useUiStore().setAlertStaffAccount(input.updater);
        useUiStore().setAlertItemType(AlertItemTypeEnum.PATIENT);
        useUiStore().setAlertAction(AlertActionEnum.UPDATE);
        return;
      }
      await patientStore.fetchPatient(input.id, true);
    },
    async handleAppointmentGroupUpdate(input: { id: string; updater: string }) {
      const appointmentGroupStore = useAppointmentGroupStore();
      const oldApptGroup = appointmentGroupStore.appointmentGroups[input.id];
      if (!oldApptGroup) return;
      if (
        this.selectedAppointmentGroupId === oldApptGroup._id &&
        useUiStore().currentUser?.email !== input.updater
      ) {
        useUiStore().setAlertDialog(true);
        useUiStore().setAlertStaffAccount(input.updater);
        useUiStore().setAlertItemType(AlertItemTypeEnum.APPOINTMENT_GROUP);
        useUiStore().setAlertAction(AlertActionEnum.UPDATE);
        return;
      }
      await useAppointmentGroupStore().fetchAppointmentGroup(input.id, true);
    },
    async handleTimeSlotUpdate(id: string) {
      const timeSlot = useTimeSlotStore().timeSlots[id];
      if (timeSlot?.date === this.date) {
        // Only fetch timeslot when viewing timeslot
        useTimeSlotStore().fetchTimeSlot(id);
      }
    },
    async handleAppointmentUpdate(input: { id: string; updater: string }) {
      const appointmentStore = useAppointmentStore();
      const oldAppointment = appointmentStore.appointments[input.id];
      if (!oldAppointment) return;
      if (
        this.selectedAppointmentId === oldAppointment._id &&
        useUiStore().currentUser?.email !== input.updater
      ) {
        useUiStore().setAlertDialog(true);
        useUiStore().setAlertStaffAccount(input.updater);
        useUiStore().setAlertItemType(AlertItemTypeEnum.APPOINTMENT);
        useUiStore().setAlertAction(AlertActionEnum.UPDATE);
        return;
      }
      await appointmentStore.fetchAppointment(input.id);
    },
    setSelectedAppointmentGroupType(
      appointmentGroupType?: AppointmentGroupType
    ) {
      if (this.selectedAppointmentGroupType !== appointmentGroupType) {
        this.selectedAppointmentGroupType = appointmentGroupType;
        this.unsetMetadataForAppointmentGroup("NEW_APPOINTMENT_GROUP");
      }
      if (!this.selectedAppointmentGroupType) {
        this.setAppointmentGroupCategory();
        return;
      }
      const { appointmentMetadata } = useAppointmentStore();
      if (!appointmentMetadata) return;
      this.setAppointmentGroupCategory(
        appointmentMetadata[this.selectedAppointmentGroupType].category
      );
    },
    setSelectedALaCarteItems(aLaCarteItems?: ALaCarteItem[]) {
      if (this.selectedALaCarteItems !== aLaCarteItems) {
        this.selectedALaCarteItems = aLaCarteItems;
      }
    },
    setPriceFactor(priceFactor?: PriceFactor): void {
      if (this.priceFactor !== priceFactor) {
        this.priceFactor = priceFactor;
        this.unsetMetadataForAppointmentGroup("NEW_APPOINTMENT_GROUP");
      }
    },
    importValuesFromAppointmentRequest() {
      const { dueDate, fetusCount, site, dob, newBornDob, type } =
        this.selectedAppointmentRequest ?? {};
      this.setDueDate(dueDate);
      this.setFetusCount(fetusCount);
      this.setSite(site);
      if (type && type === "newborn") {
        this.setNewBornDob(newBornDob);
      } else {
        this.setPatientDob(dob);
      }
    },
    setSelectedAppointmentGroup(id?: string): void {
      this.selectedAppointmentGroupId = id;
      if (id) {
        const appointmentGroupStore = useAppointmentGroupStore();
        const appointmentGroup = appointmentGroupStore.appointmentGroups[id];
        if (appointmentGroup) {
          this.setSelectedPatient(appointmentGroup.patientId);
          this.setAppointmentGroupCategory(appointmentGroup.category);
          if (
            this.selectedAppointment &&
            this.selectedAppointment.appointmentGroupId !== appointmentGroup._id
          ) {
            this.setSelectedAppointmentId();
          }
          if (appointmentGroup.category === "fetal") {
            this.setDueDate(appointmentGroup.dueDate);
            this.setFetusCount(appointmentGroup.fetusCount);
          }
          this.setSelectedAppointmentGroupType(appointmentGroup.type);
          this.setSelectedALaCarteItems(appointmentGroup.aLaCarteItems);
          this.setPriceFactor(appointmentGroup.priceFactor);
          this.setSite(appointmentGroup.site);
          return;
        }
      }
      this.resetAppointmentGroupData();
    },
    resetAppointmentGroupData() {
      this.setAppointmentGroupCategory();
      this.setSelectedAppointmentId();
      this.setDueDate(this.dueDate);
      this.setFetusCount();
      this.setSelectedAppointmentGroupType();
      this.setSelectedALaCarteItems();
      this.setPriceFactor();
      this.setSite();
    },
    resetAllData() {
      this.unsetMetadataForSelectedAppointmentGroup();
      this.setSelectedAppointmentGroup();
      this.setSelectedPatient();
      this.setSelectedAppointmentRequestId();
      this.setTemporarySelectedAppointmentRequest();
      this.setSelectedCheckTypes();
    },
    setCheckTypesMetadata(
      appointmentGroupId: string,
      metadata: AppointmentGroupCheckTypesMetadata
    ) {
      this.checkTypesMetadataByAppointmentGroupId = {
        ...this.checkTypesMetadataByAppointmentGroupId,
        [appointmentGroupId]: {
          ...this.checkTypesMetadataByAppointmentGroupId[appointmentGroupId],
          ...metadata,
        },
      };
    },
    async deleteAppointment(
      timeSlotId: string,
      appointmentId: string,
      deleteReason?: string
    ): Promise<void> {
      const appointment = useAppointmentStore().appointments[appointmentId];
      if (appointment) {
        await useAppointmentStore().deleteAppointment(
          appointmentId,
          deleteReason
        );
        const { checkTypes } = appointment;
        checkTypes.forEach(this.unsetCheckType);
        await useTimeSlotStore().fetchTimeSlot(timeSlotId);
      }
    },
    async deleteAppointmentGroup(
      appointmentGroupId: string,
      deleteReason?: string
    ): Promise<void> {
      const timeSlotIdsSet = new Set<string>();
      useAppointmentStore()
        .getAppointmentsForAppointmentGroup(appointmentGroupId)
        .map((appointment) => {
          timeSlotIdsSet.add(appointment.timeSlotId);
          return useAppointmentStore().deleteAppointmentFromStore(
            appointment._id
          );
        });
      if (this.selectedAppointmentGroupId === appointmentGroupId) {
        this.selectedAppointmentGroupId = undefined;
      }
      await useAppointmentGroupStore().deleteAppointmentGroup(
        appointmentGroupId,
        deleteReason
      );
      await Promise.all(
        Array.from(timeSlotIdsSet).map((timeSlotId) =>
          useTimeSlotStore().fetchTimeSlot(timeSlotId)
        )
      );
    },
    revertCheckTypeMetadata() {
      // Restore metadata
      if (this.backupAppointmentMetadata) {
        if (this.selectedAppointmentGroupId) {
          this.setCheckTypesMetadata(
            this.selectedAppointmentGroupId,
            this.backupAppointmentMetadata
          );
        }
        this.setSelectedCheckTypes();
        this.backupAppointmentMetadata = undefined;
      }
      // Reselect appointment that was being rescheduled
      if (this.rescheduleFromAppointmentId) {
        this.setSelectedAppointmentId(this.rescheduleFromAppointmentId);
        this.rescheduleFromAppointmentId = undefined;
      }
    },
    unsetCheckType(checkType: CheckType) {
      // Unselect check type
      if (this.selectedCheckTypes) {
        const selectedCheckTypes =
          this.selectedCheckTypes?.filter(
            (selectedCheckType) => selectedCheckType !== checkType
          ) || undefined;
        this.setSelectedCheckTypes(selectedCheckTypes);
      }
      if (this.selectedAppointmentGroupId) {
        // remove from metadata
        const metadata = {
          ...this.checkTypesMetadata,
        };
        delete metadata[checkType];
        this.checkTypesMetadataByAppointmentGroupId = {
          ...this.checkTypesMetadataByAppointmentGroupId,
          [this.selectedAppointmentGroupId]: metadata,
        };
      }
    },
    unsetMetadataForSelectedAppointmentGroup() {
      if (this.selectedAppointmentGroupId) {
        this.unsetMetadataForAppointmentGroup(this.selectedAppointmentGroupId);
      }
    },
    unsetMetadataForAppointmentGroup(appointmentGroupId: string) {
      Vue.delete(
        this.checkTypesMetadataByAppointmentGroupId,
        appointmentGroupId
      );
    },
    setTimeSlotCheckType(
      timeSlotId: string,
      calendarEventId: string,
      appointmentId?: string
    ): void {
      if (this.selectedCheckTypes) {
        this.selectedCheckTypes.forEach((checkType) => {
          if (this.selectedAppointmentGroupId) {
            let locationType: AppointmentLocationType | undefined;
            if (appointmentId) {
              const appt = useAppointmentStore().appointments[appointmentId];
              locationType = appt?.locationType;
            }
            this.setCheckTypesMetadata(this.selectedAppointmentGroupId, {
              [checkType]: {
                timeSlotId,
                calendarEventId,
                locationType: locationType ?? "in_person",
                appointmentId: appointmentId,
                isDirty: true,
              },
            });
          }
        });
        if (this.selectedAppointmentGroupId) {
          this.selectedCheckTypes = undefined;
        }
      }
    },
    setSelectedCheckTypes(checkTypes?: CheckType[]): void {
      this.selectedCheckTypes = checkTypes;
      this.selectedAppointmentId = undefined;
    },
    rescheduleAppointmentment(rescheduleFromAppointmentId: string): void {
      if (this.checkTypesMetadata) {
        this.rescheduleFromAppointmentId = rescheduleFromAppointmentId;
        const checkTypes: CheckType[] = [];
        Object.entries(this.checkTypesMetadata).forEach(([key, val]) => {
          if (val.appointmentId === rescheduleFromAppointmentId) {
            const checkType = key as CheckType;
            checkTypes.push(checkType);
          }
        });
        this.backupAppointmentMetadata = this.checkTypesMetadata;
        this.setSelectedCheckTypes(checkTypes);
      }
    },
    setAppointmentGroupCategory(
      appointmentGroupCategory?: AppointmentGroupCategory
    ) {
      this.appointmentGroupCategory = appointmentGroupCategory;
    },
    setDueDate(date?: string): void {
      this.dueDate = date || undefined;
    },
    setFetusCount(fetusCount?: number): void {
      this.fetusCount = fetusCount;
    },
    setPatientDob(dob?: string): void {
      this.patientDob = dob;
    },
    setSite(site?: ClinicSite): void {
      this.site = site;
    },
    setNewBornDob(newBornDob?: string): void {
      this.newBornDob = newBornDob;
    },
    setSelectedAppointmentId(id?: string): void {
      this.selectedAppointmentId = id;
      if (id) {
        this.calendarType = CalendarType.CATEGORY;
      } else {
        this.rescheduleFromAppointmentId = undefined;
      }
      const urlParams = useUrlSearchParams<{ appointmentId?: string }>(
        "history"
      );
      if (urlParams.appointmentId !== id) {
        router.replace({
          query: { appointmentId: id },
        });
      }
    },
    setNewPatientMode(): void {
      this.setNewAppointmentGroupMode();
      this.setPatientSelectionMode(PatientSelectionMode.NEW);
      this.selectedPatientId = undefined;
    },
    setSelectedPatient(id?: string) {
      this.selectedPatientId = id;
      if (id) {
        this.setPatientSelectionMode(PatientSelectionMode.EXISTING);
        this.setPatientDob(usePatientStore().patients[id]?.dob);
      } else {
        this.setPatientSelectionMode();
        this.setPatientDob();
      }
    },
    setSelectedAppointmentRequestId(id?: string) {
      this.selectedAppointmentRequestId = id;
      this.importValuesFromAppointmentRequest();
    },
    setTemporarySelectedAppointmentRequest(dto?: AppointmentRequestDTO) {
      this.temporaryAppointmentRequest = dto;
      this.importValuesFromAppointmentRequest();
    },
    setMonthlyCalendarViewMode(viewMode: MonthlyCalendarViewMode) {
      this.monthlyCalendarViewMode = viewMode;
    },
    setPatientSelectionMode(mode?: PatientSelectionMode) {
      this.patientSelectionMode = mode;
    },
    async _fetchAndSelectPatient(id: string, force = false) {
      this.setSelectedPatient(id);
      const patient = await usePatientStore().fetchPatient(id, force);
      if (patient) {
        this.setPatientDob(patient.dob);
      }
    },
    async _fetchAndSelectAppointmentGroup(id: string, force = false) {
      await useAppointmentGroupStore().fetchAppointmentGroup(id, force);
      this.setSelectedAppointmentGroup(id);
    },
    async fetchOverviewsForCurrentView() {
      let start = this.date;
      let end: string;
      start = this.date;
      if (this.isCustomWeekView) {
        end = moment(start)
          .add(3, "weeks")
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      } else {
        start = moment(start).format("YYYY-MM-01");
        end = moment(start)
          .add(1, "month")
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      }
      await useOverviewStore().fetchOverviews(start, end);
    },
    async selectAppointment(
      appointmentId: string,
      patientId: string,
      appointmentGroupId: string
    ): Promise<void> {
      this.setSelectedAppointmentId(appointmentId);
      this.loadPatientAndAppointmentGroup(patientId, appointmentGroupId);
    },
    async loadPatientAndAppointmentGroup(
      patientId: string,
      appointmentGroupId: string,
      force = false
    ): Promise<void> {
      this.isLoadingAppointmentGroups = true;
      this.setSelectedAppointmentRequestId();
      await Promise.all([
        this._fetchAndSelectPatient(patientId, force),
        this._fetchAndSelectAppointmentGroup(appointmentGroupId, force),
      ]);
      this.isLoadingAppointmentGroups = false;
      try {
        await this.fetchAppointmentsForSelectedAppointmentGroup();
      } catch (error) {
        // 在總覽畫面點被刪除的套餐會找不到約診是正常的
      }
    },
    async fetchAppointmentsForSelectedAppointmentGroup() {
      const appointmentGroupId = this.selectedAppointmentGroupId;
      if (!appointmentGroupId) {
        return;
      }
      this.appointmentGroupAppointmentsLoadingStatus = {
        ...this.appointmentGroupAppointmentsLoadingStatus,
        [appointmentGroupId]: "loading",
      };
      const appointments =
        await useAppointmentStore().fetchAppointmentsForAppointmentGroup(
          appointmentGroupId
        );
      const appointmentGroupCheckTypesMetadata: AppointmentGroupCheckTypesMetadata =
        {};
      appointments.forEach((rawAppointment) => {
        rawAppointment.checkTypes.forEach((checkType) => {
          appointmentGroupCheckTypesMetadata[checkType] = {
            timeSlotId: rawAppointment.timeSlotId,
            locationType: rawAppointment.locationType ?? "in_person",
            appointmentId: rawAppointment._id,
          };
        });
      });
      this.setCheckTypesMetadata(
        appointmentGroupId,
        appointmentGroupCheckTypesMetadata
      );
      this.appointmentGroupAppointmentsLoadingStatus = {
        ...this.appointmentGroupAppointmentsLoadingStatus,
        [appointmentGroupId]: "loaded",
      };
    },
    async createAppointmentsAndRefreshAppointmentGroup(
      patientInput: PatientInput
    ): Promise<void> {
      if (
        !this.selectedAppointmentGroupType ||
        !this.priceFactor ||
        !this.site ||
        !this.checkTypesMetadata ||
        !this.appointmentGroupCategory
      ) {
        return;
      }
      let appointmentGroupInput: AppointmentGroupInput;
      if (this.appointmentGroupCategory === "fetal") {
        if (!this.dueDate || !this.fetusCount) return;
        appointmentGroupInput = {
          category: this.appointmentGroupCategory,
          type: this.selectedAppointmentGroupType,
          aLaCarteItems: this.selectedALaCarteItems || undefined,
          priceFactor: this.priceFactor,
          site: this.site,
          dueDate: this.dueDate,
          fetusCount: this.fetusCount,
          source: this.selectedAppointmentRequest?.source,
        };
      } else {
        appointmentGroupInput = {
          category: this.appointmentGroupCategory,
          type: this.selectedAppointmentGroupType,
          aLaCarteItems: this.selectedALaCarteItems || undefined,
          priceFactor: this.priceFactor,
          site: this.site,
          source: this.selectedAppointmentRequest?.source,
        };
      }
      const appointmentStore = useAppointmentStore();
      const rawAppointments = await appointmentStore.createAppointments({
        patientId: this.selectedPatient?._id,
        appointmentGroupId: this.selectedAppointmentGroup?._id,
        patientInput: {
          dob: patientInput.dob,
          taiwanId: patientInput.taiwanId,
          email: patientInput.email,
          chineseName: patientInput.chineseName,
          englishName: patientInput.englishName,
          primaryMobile: patientInput.primaryMobile,
          secondaryMobile: patientInput.secondaryMobile,
          address: patientInput.address,
          obstetrician: patientInput.obstetrician,
          mamahealthId: patientInput.mamahealthId,
          cooperativeHospital: patientInput.cooperativeHospital,
          cooperativeHospitalMedicalHistoryNumber:
            patientInput.cooperativeHospitalMedicalHistoryNumber,
          memo: patientInput.memo,
        },
        appointmentGroupInput,
        appointmentRequestId: this.selectedAppointmentRequestId,
        metadata: this.checkTypesMetadata,
      });
      if (rawAppointments?.length) {
        await this.fetchTimeSlotsForCurrentDate();
        const { appointmentGroupId, patientId: newPatientId } =
          rawAppointments[0];
        if (newPatientId && appointmentGroupId) {
          await this.loadPatientAndAppointmentGroup(
            newPatientId,
            appointmentGroupId,
            true
          );
        }
        if (this.selectedAppointmentRequestId) {
          this.handleGoToLastStep();
        }
        this.rescheduleFromAppointmentId = undefined;
      }
      this.unsetMetadataForAppointmentGroup("NEW_APPOINTMENT_GROUP");
    },
    async selectAppointmentRequest(apptReq: AppointmentRequestDTO) {
      this.setNewPatientMode();
      this.setSelectedAppointmentRequestId(apptReq._id);
    },
    handleGoToLastStep() {
      this.resetAllData();
    },
    async updateAppointmentRequest(
      payload: AppointmentRequestEditInput
    ): Promise<void> {
      if (this.selectedAppointmentRequestId) {
        const appointmentRequestStore = useAppointmentRequestStore();
        await appointmentRequestStore.staffUpdate(
          this.selectedAppointmentRequestId,
          payload
        );
      }
    },
    async updateAppointmentGroup(
      appointmentGroupId: string,
      payload: AppointmentGroupEditInput
    ): Promise<void> {
      const appointmentGroupStore = useAppointmentGroupStore();
      await appointmentGroupStore.updateAppointmentGroup(
        appointmentGroupId,
        payload
      );
      const appointmentGroup =
        appointmentGroupStore.appointmentGroups[appointmentGroupId];
      if (appointmentGroup) {
        await this.loadPatientAndAppointmentGroup(
          appointmentGroup.patientId,
          appointmentGroup._id
        );
        await useTimeSlotStore().fetchTimeSlotsForDate(this.date);
      }
    },
    async fetchTimeSlotsForCurrentDate() {
      await useTimeSlotStore().fetchTimeSlotsForDate(this.date);
    },
    async fetchDayRemarkForCurrentDate() {
      await useDayRemarkStore().fetchDayRemark(this.date);
    },
    async fetchDayQrCodeForCurrentDate() {
      await useDayQrCodeStore().fetchDayQrCode(this.date);
    },
    async removeTimeSlot(id: string): Promise<void> {
      const timeSlotStore = useTimeSlotStore();
      await timeSlotStore.removeTimeSlot(id);
      this.fetchOverviewsForCurrentView();
    },
    setDateRange(date: string): void {
      this.setCalendarType(CalendarType.MONTH);
      this.setDate(date);
    },
    setCalendarType(calendarType: CalendarType): void {
      this.calendarType = calendarType;
      if (calendarType === CalendarType.MONTH) {
        this.fetchOverviewsForCurrentView();
      }
      this.setSelectedAppointmentId();
    },
    setDate(date?: string): void {
      if (this.date !== date) {
        router
          .replace({ query: { ...router.currentRoute.query, date } })
          .catch(() => {
            // no-op
          });
      }
      this.date = date || new Date().toISOString().split("T")[0];
      if (this.calendarType === CalendarType.CATEGORY) {
        this.fetchTimeSlotsForCurrentDate();
        this.fetchDayRemarkForCurrentDate();
      } else {
        this.fetchOverviewsForCurrentView();
      }
    },
    setNewAppointmentGroupMode(): void {
      this.resetAppointmentGroupData();
      this.setSelectedAppointmentGroup("NEW_APPOINTMENT_GROUP");
    },
    selectedCheckTypeClicked(checkType: CheckType): void {
      const checkTypeMetadata = this.checkTypesMetadata?.[checkType];
      const existingAppointmentId = checkTypeMetadata?.appointmentId;
      this.setCalendarType(CalendarType.CATEGORY);
      if (existingAppointmentId) {
        const appointmentStore = useAppointmentStore();
        const appointment =
          appointmentStore.appointments[existingAppointmentId];
        if (appointment) {
          this.setDate(new Date(appointment.start).toISOString().split("T")[0]);
          this.setSelectedAppointmentId(existingAppointmentId);
        }
      } else {
        this.setSelectedCheckTypes([checkType]);
      }
    },
    locationTypeClicked(
      appointmentGroupId: string,
      checkType: CheckType,
      locationType: AppointmentLocationType
    ): void {
      const metadata =
        this.checkTypesMetadataByAppointmentGroupId[appointmentGroupId][
          checkType
        ];
      if (metadata) {
        this.checkTypesMetadataByAppointmentGroupId[appointmentGroupId][
          checkType
        ] = {
          ...metadata,
          locationType,
        };
      }
    },
    updateMetadataByAppointment(appointment: Appointment) {
      const { appointmentGroupId, checkTypes, timeSlotId, locationType, _id } =
        appointment;
      for (const checkType of checkTypes) {
        const metadata =
          this.checkTypesMetadataByAppointmentGroupId[appointmentGroupId][
            checkType
          ];
        if (metadata) {
          this.checkTypesMetadataByAppointmentGroupId[appointmentGroupId][
            checkType
          ] = {
            ...metadata,
            timeSlotId,
            locationType: locationType ?? "in_person",
            appointmentId: _id,
            isDirty: false,
          };
        }
      }
    },
    async createTimeSlot(input: TimeSlotInput): Promise<void> {
      const timeSlotStore = useTimeSlotStore();
      await timeSlotStore.createTimeSlot(input);
      this.fetchOverviewsForCurrentView();
    },
    setIsCustomWeekMode(isCustomWeekMode: boolean): void {
      this.isCustomWeekMode = isCustomWeekMode;
    },
    async createTransaction(transaction: TransactionInput): Promise<void> {
      await useTransactionStore().createTransaction(transaction);
      await Promise.all([
        useAppointmentGroupStore().fetchAppointmentGroup(
          transaction.appointmentGroupId,
          true
        ),
        useAppointmentStore().fetchAppointmentsForAppointmentGroup(
          transaction.appointmentGroupId
        ),
        useReceiptStore().fetchReceiptsForAppointmentGroup(
          transaction.appointmentGroupId,
          true
        ),
      ]);
    },
    async updateTransaction(
      transactionId: string,
      transaction: TransactionEditInput,
      appointmentGroupId: string
    ): Promise<void> {
      await useTransactionStore().updateTransaction(transactionId, transaction);
      await Promise.all([
        useAppointmentGroupStore().fetchAppointmentGroup(
          appointmentGroupId,
          true
        ),
        useAppointmentStore().fetchAppointmentsForAppointmentGroup(
          appointmentGroupId
        ),
        useReceiptStore().fetchReceiptsForAppointmentGroup(
          appointmentGroupId,
          true
        ),
      ]);
    },
  },
  getters: {
    roomIds(state) {
      return [
        ...(state.selectedPatientId
          ? [getRoomId(AlertItemTypeEnum.PATIENT, state.selectedPatientId)]
          : []),
        ...(state.selectedAppointmentGroupId &&
        state.selectedAppointmentGroupId !== "NEW_APPOINTMENT_GROUP"
          ? [
              getRoomId(
                AlertItemTypeEnum.APPOINTMENT_GROUP,
                state.selectedAppointmentGroupId
              ),
            ]
          : []),
        ...(state.selectedAppointmentId
          ? [
              getRoomId(
                AlertItemTypeEnum.APPOINTMENT,
                state.selectedAppointmentId
              ),
            ]
          : []),
        ...(state.selectedAppointmentRequestId
          ? [
              getRoomId(
                AlertItemTypeEnum.APPOINTMENT_REQUEST,
                state.selectedAppointmentRequestId
              ),
            ]
          : []),
        ...(state.calendarType === CalendarType.CATEGORY
          ? [getRoomId(AlertItemTypeEnum.CATEGORY_CALENDAR, state.date)]
          : []),
        ...(state.calendarType === CalendarType.MONTH
          ? [
              getRoomId(
                AlertItemTypeEnum.MONTHLY_CALENDAR,
                state.date.substring(0, 7)
              ),
            ]
          : []),
      ].sort();
    },
    isCurrentAppointmentGroupAppointmentsLoading: (state) => {
      return (
        !!state.selectedAppointmentGroupId &&
        state.appointmentGroupAppointmentsLoadingStatus[
          state.selectedAppointmentGroupId
        ] === "loading"
      );
    },
    isCurrentAppointmentGroupAppointmentsLoaded: (state) => {
      if (state.selectedAppointmentGroupId === "NEW_APPOINTMENT_GROUP") {
        return true;
      }
      return (
        !!state.selectedAppointmentGroupId &&
        state.appointmentGroupAppointmentsLoadingStatus[
          state.selectedAppointmentGroupId
        ] === "loaded"
      );
    },
    appointmentFocusedMode: (state) => {
      return (
        !!state.selectedAppointmentGroupId && !!state.selectedAppointmentId
      );
    },
    selectedAppointmentRequest: (state) => {
      if (state.temporaryAppointmentRequest) {
        return state.temporaryAppointmentRequest;
      }
      if (!state.selectedAppointmentRequestId) return;
      const appointmentRequestStore = useAppointmentRequestStore();
      return appointmentRequestStore.appointmentRequests[
        state.selectedAppointmentRequestId
      ];
    },
    selectedAppointment: (state) => {
      if (!state.selectedAppointmentId) {
        return undefined;
      }
      const appointmentStore = useAppointmentStore();
      return appointmentStore.appointments[state.selectedAppointmentId];
    },
    selectedAppointmentGroup: (state) => {
      if (
        !state.selectedAppointmentGroupId ||
        state.selectedAppointmentGroupId === "NEW_APPOINTMENT_GROUP"
      ) {
        return undefined;
      }
      const appointmentGroupStore = useAppointmentGroupStore();
      return appointmentGroupStore.appointmentGroups[
        state.selectedAppointmentGroupId
      ];
    },
    checkTypesMetadata: (state) => {
      return state.selectedAppointmentGroupId
        ? state.checkTypesMetadataByAppointmentGroupId[
            state.selectedAppointmentGroupId
          ]
        : undefined;
    },
    selectedPatient(state) {
      const patientStore = usePatientStore();
      return state.selectedPatientId
        ? patientStore.patients[state.selectedPatientId]
        : undefined;
    },
    appointmentGroupsForSelectedPatient(state) {
      return state.selectedPatientId
        ? useAppointmentGroupStore().getAppointmentGroupsForPatient(
            state.selectedPatientId
          )
        : [];
    },
    validSitesForCurrentSelection(state) {
      if (state.selectedAppointmentGroupType && state.priceFactor) {
        const sites =
          useAppointmentStore().appointmentMetadata?.[
            state.selectedAppointmentGroupType
          ].priceFactors[state.priceFactor]?.sites;
        if (sites) {
          return Object.keys(sites);
        }
      }
      return undefined;
    },
    timeSlotsForCurrentDate(state) {
      if (state.calendarType === CalendarType.CATEGORY) {
        return useTimeSlotStore().getTimeSlotsByDate(state.date);
      }
      return [];
    },
    isMonthCalendarView(state) {
      return (
        state.calendarType === CalendarType.MONTH && !state.isCustomWeekMode
      );
    },
    isCustomWeekView(state) {
      return (
        state.calendarType === CalendarType.MONTH && state.isCustomWeekMode
      );
    },
    isCategoryView(state) {
      return state.calendarType === CalendarType.CATEGORY;
    },
  },
});
